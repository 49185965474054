.workspace
  display: grid
  grid-template-columns: minmax(343px, 1fr) minmax(0, 3fr)
  grid-template-rows: 72px minmax(0, 1fr)
  grid-gap: 12px
  max-width: 1680px
  height: 100svh
  margin: 0 auto
  padding: 16px 24px

  @media #{$laptop}
    grid-template-columns: 343px minmax(0, auto)
    grid-template-rows: 56px minmax(0, 1fr)

  @media #{$tablet}
    grid-template-columns: minmax(0, 1fr)
    grid-template-rows: 48px minmax(0, 1fr)
    padding: 16px
