.advice
  display: flex
  justify-content: space-between
  gap: 16px
  min-height: 40px
  padding: 8px 16px
  background: #eaf8ff
  border-radius: 8px

  #{$dark-theme}
    background: #45566e

  &_alt
    padding: 8px 4px

  &__content
    display: flex
    flex-direction: column
    justify-content: center

    .advice_alt &
      padding-left: 12px
