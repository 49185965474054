.time-input
  position: relative
  width: 100%
  max-width: 88px

  &__field
    width: 100%
    height: 40px
    padding: 0 20px 0 2px
    background: $gray-150
    border-radius: 6px
    font-size: 16px
    line-height: 20px
    color: $gray-850
    text-align: center
    transition: opacity $tr-options
    cursor: text

    #{$dark-theme}
      background: $gray-650
      color: $white

    &:hover
      @media (hover)
        opacity: .8

    &::-webkit-calendar-picker-indicator
      display: none

  &__btn
    position: absolute
    top: 5px
    right: 0
