.tooltip
  position: absolute
  visibility: hidden
  width: max-content
  padding: 12px
  background: rgb(14,17,20, .8)
  border-radius: 8px
  opacity: 0
  color: $white
  transition: visibility $tr-options .15s, opacity $tr-options .15s
  pointer-events: none
  z-index: 2
