.select
  position: relative
  width: 100%
  max-width: 360px
  user-select: none

  &_active
    & > .select
      &__field
        & > .icon_filter
          fill: $primary-light

      &__dropdown
        visibility: visible
        opacity: 1
        pointer-events: auto

  &_alt
    width: fit-content

    .select
      &__field
        height: auto
        padding: 0
        border: none

      &__dropdown
        border-radius: 12px

  &__field
    display: flex
    justify-content: space-between
    align-items: center
    height: 50px
    padding: 6px 12px
    border: 1px solid $gray-250
    border-radius: 6px
    transition: border-color $tr-options
    cursor: pointer

    #{$dark-theme}
      border-color: #9fa9c5

    & > .icon
      transition: fill $tr-options, opacity $tr-options

      &:not(.icon_filter)
        fill: $blue-tg

    &:hover
      @media (hover)

        & > .icon:not(.icon_filter)
          opacity: .8

        & > .icon_filter
          fill: $primary-light

    &_error
      border-color: $error

  &__dropdown
    position: fixed
    top: 0
    left: 0
    width: 100svw
    height: 100svh
    visibility: hidden
    opacity: 0
    z-index: 3
    transition: visibility $tr-options, opacity $tr-options
    pointer-events: none

    &-wrapper
      position: fixed
      width: max-content
      max-width: calc(100svw - 16px)
      max-height: calc(100svh - 16px)
      padding: 14px 0
      background: $white
      border-radius: 6px
      box-shadow: $box-shadow

      #{$dark-theme}
        background: rgb(83, 84, 97, .98)

  &__optgroup
    padding: 0 8px

  &__option
    display: flex
    justify-content: space-between
    align-items: center
    padding: 6px 16px
    transition: background $tr-options

    & > .icon
      position: relative
      left: 8px
      fill: #57d163

    .select_multiple &
      cursor: pointer

    &:not(&_active)
      cursor: pointer

      & > .icon
        visibility: hidden
        opacity: 0
        transition: visibility $tr-options, opacity $tr-options

      &:hover
        @media (hover)
          background: #eef1f4

          #{$dark-theme}
            background: $gray-770

    &_active
      background: #eef1f4

      #{$dark-theme}
        background: $gray-770

      & > .icon
        visibility: visible
        opacity: 1

  &__group
    display: flex
    flex-direction: column
    min-width: 0

    &_gap
      gap: 4px

  &__comment
    display: flex
    gap: 6px
