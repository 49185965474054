.radio-alt
  max-width: 100%

  &__item
    padding: 8px
    border-bottom: 2px solid $gray-300
    transition: background $tr-options, opacity .1s ease-in-out
    cursor: pointer
    user-select: none

    #{$dark-theme}
      border-color: $gray-600

    .icon
      #{$dark-theme}
        fill: $blue-tg

    .text_color-green
      #{$dark-theme}
        color: $blue-tg

    &:focus
      background: $gray-250

      #{$dark-theme}
        background: $gray-770

    &:hover
      @media (hover)
        background: $gray-250

        #{$dark-theme}
          background: $gray-770

    &_active
      background: $gray-250

      #{$dark-theme}
        background: $gray-770

      &:active
        opacity: .8
