.dropzone
  visibility: hidden
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  padding: 48px
  opacity: 0
  transition: visibility $tr-options, opacity $tr-options
  z-index: 2

  &_active
    visibility: visible
    opacity: 1

  &__wrapper
    height: 100%
    padding: 40px
    background: $white
    border-radius: 20px
    box-shadow: $box-shadow

    #{$dark-theme}
      background: #1f2b39

    &_active
      .dropzone__border
        #{$any-theme}
          border-color: $primary-light

  &__border
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 8px
    height: 100%
    border: 2px dashed $gray-850
    border-radius: 4px
    text-align: center
    transition: border-color $tr-options

    #{$dark-theme}
      border-color: $gray-350

    .text
      max-width: 288px

    .icon
      width: 80px
      height: 80px
