.multi-vcard

  &__profile-images
    display: flex

    & > .profile-image

      &:first-of-type
        z-index: 2

      &:nth-of-type(2)
        z-index: 1

      &:not(:first-of-type)
        margin-left: -36px
