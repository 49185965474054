.progress-bar
  width: 272px
  max-width: 100%

  &_max-width
    width: 100%

  &__text
    line-height: 20px

  &__thumb
    height: 8px
    background: #d9d9d9
    border-radius: 4px

    .progress-bar_alt &
      height: 2px

  &__track
    height: 100%
    background: $green
    border-radius: inherit
    transition: width ease-in-out .3s
