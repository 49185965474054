.select-alt
  user-select: none

  &__field
    position: relative
    display: flex
    justify-content: center
    align-items: center
    width: 72px
    height: 72px
    padding: 12px
    border: 1px solid $gray-300
    border-radius: 12px
    cursor: pointer

    #{$dark-theme}
      background: $gray-650
      border-color: transparent

    img
      width: 100%
      max-width: 100%
      max-height: 100%

    .icon
      position: absolute
      bottom: 2px
      right: 2px

      .select-alt_active &
        fill: $pressed

      .select-alt__field
        &:hover
          @media (hover)
            .icon
              fill: $pressed

    &:hover
      @media (hover)
        .icon
          fill: $pressed

  &__label
    position: relative
    display: flex
    width: 100%
    aspect-ratio: 1 / 1
    background: #2f59cc
    border: 4px solid #cdd6ed
    border-radius: 50%
    fill: $white

  &__overlay
    @include overlay

    .select-alt_active &
      visibility: visible
      opacity: 1
      pointer-events: auto

  &__dropdown
    position: fixed
    display: flex
    gap: 8px
    flex-wrap: wrap
    max-width: 248px
    padding: 8px
    background: #f8f8f8
    border-radius: 12px
    box-shadow: $box-shadow

    #{$dark-theme}
      background: $gray-650

  &__option
    position: relative
    display: flex
    justify-content: center
    align-items: center
    width: 72px
    height: 72px
    padding: 12px
    background: $white
    border: 1px solid $gray-300
    border-radius: 12px
    transition: border-color $tr-options
    cursor: pointer

    #{$dark-theme}
      background: $gray-650
      border-color: $gray-600

    &:hover
      @media (hover)
        border-color: $green

    &_active
      border-color: $green
      pointer-events: none

  &__checkbox
    position: absolute
    bottom: 8px
    right: 8px
    display: flex
    justify-content: center
    align-items: center
    width: 24px
    height: 24px
    background: rgb(0, 0, 0, .1)
    border: 1px solid $white
    border-radius: 50%
    transition: background $tr-options, border-color $tr-options
    box-sizing: border-box

    .select-alt__option_active &
      background: $green
      border-color: $green

    .icon
      opacity: 0
      transition: opacity $tr-options

      .select-alt__option_active &
        opacity: 1
