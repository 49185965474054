.qr-code
  display: flex
  justify-content: center
  align-items: center
  aspect-ratio: 1 / 1

  &__image
    width: 100%
    height: 100%
    background-repeat: no-repeat
    background-size: contain
