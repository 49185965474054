.stories
  position: fixed
  top: 0
  left: 0
  display: grid
  grid-template-columns: minmax(0, 1fr)
  justify-items: center
  align-items: center
  width: 100%
  height: 100%
  padding: 16px
  background: rgba(49, 67, 74, .8)
  opacity: 0
  transition: opacity $tr-options
  z-index: 2
  pointer-events: auto

  &_active
    opacity: 1
    animation: show $tr-options

  &__modal
    aspect-ratio: 9 / 16
    height: 100%
    max-width: min(100%, 414px)
    max-height: min(100%, 736px)
    overflow: hidden
