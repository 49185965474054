.message-product
  background: $white
  border-radius: 6px

  #{$dark-theme}
    background: $gray-700

  .media
    border-radius: 6px 6px 0 0

  &__wrapper
    display: flex
    flex-direction: column
    gap: 8px
    padding: 8px 8px 16px
