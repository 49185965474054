.tariff
  position: relative
  display: flex
  flex-direction: column
  min-width: 0
  border: 1px solid $gray-300
  border-radius: 8px
  box-shadow: 0 0 8px 0px rgb(0, 0, 0, .1)

  #{$dark-theme}
    background: #565b62
    border-color: $gray-600

  &__header
    display: flex
    justify-content: space-between
    gap: 8px
    padding: 16px 20px
    border-bottom: 1px solid $gray-300

    #{$dark-theme}
      border-color: $gray-600

  &__body
    display: flex
    flex-direction: column
    justify-content: space-between
    flex-grow: 1
    gap: 32px
    padding: 16px 20px 32px

  &__options
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 20px 20px

    @media #{$laptop}
      grid-template-columns: 1fr

    @media #{$tablet}
      grid-template-columns: repeat(2, 1fr)

    @media #{$mobile-s}
      grid-template-columns: 1fr

    #{$sidebar}
      @media #{$laptop-l}
        grid-template-columns: 1fr

      @media #{$tablet}
        grid-template-columns: repeat(2, 1fr)

    &-item
      padding: 32px 0
      background: #edfdf7
      border: 1px solid transparent
      border-radius: 4px
      text-align: center
      transition: background $tr-options, border-color $tr-options
      cursor: pointer
      user-select: none

      #{$dark-theme}
        background: #5c7c70
        border: none

      & > .text
        color: $green

        #{$dark-theme}
          color: $gray-300

      &:disabled
        cursor: auto
        user-select: text

      &:not(:disabled)
        &:hover
          @media (hover)
            border-color: $green

      &_active
        &:not(:disabled)
          border-color: $green
          cursor: default
          pointer-events: none

          #{$dark-theme}
            background: #52997F
