.body
  grid-column-start: 1
  grid-column-end: 3
  grid-row-start: 2

  @media #{$tablet}
    grid-column-end: 2

  #{$sidebar}
    grid-column-start: 2
    grid-row-start: 2

    @media #{$tablet}
      grid-column-start: 1
