.input
  position: relative
  width: 100%
  max-width: 360px

  & > .icon
    position: absolute
    top: 4px
    left: 4px
    pointer-events: none

  &__placeholder
    position: absolute
    top: 13px
    left: 17px
    color: $light
    font-size: 16px
    line-height: 24px
    transition: top $tr-options, color $tr-options, font-size $tr-options, line-height $tr-options
    pointer-events: none

    #{$dark-theme}
      color: $gray-300

    .input__field:not(:placeholder-shown) ~ &
      top: 5px
      color: $text-secondary
      font-size: 12px
      line-height: 16px

      #{$dark-theme}
        color: $gray-300

  &__field
    height: 50px
    padding: 12px 16px
    outline: transparent solid 1px
    border-radius: 6px
    color: $gray-850
    font-size: 16px
    line-height: 24px
    transition: outline $tr-options
    width: 100%
    border: 1px solid $gray-250
    font-weight: 500
    transition: padding-top $tr-options, outline $tr-options

    #{$dark-theme}
      border-color: #9fa9c5
      color: $white

    &::placeholder
      color: $light

    &:not(&_alt):not(:placeholder-shown)
      padding-top: 20px
      padding-bottom: 4px

    &[type='search']
      padding: 0 40px

      &::-webkit-search-cancel-button
        display: none

    &_error
      outline: $error solid 1px

    &_gray
      background: $gray-150

      #{$dark-theme}
        background: $gray-650

    &_light-gray
      background: $gray-100

      #{$dark-theme}
        background: $gray-600

    &_alt
      height: 40px
      border: none
      font-weight: initial

  &__btn
    visibility: hidden
    position: absolute
    top: 4px
    right: 4px
    opacity: 0
    transition: visibility $tr-options, opacity $tr-options

    &_active
      visibility: visible
      opacity: 1
