.footer
  background: $gray-100
  border-top: 2px solid $outline
  border-radius: 0 0 16px 16px
  z-index: 1

  #{$dark-theme}
    background: $gray-700
    border-color: $gray-600

  .context-menu
    top: initial
    bottom: 56px
    left: 12px

    &__wrapper
      position: initial

  &_alt
    flex-shrink: 0
    height: 32px

  &__reply
    display: flex
    justify-content: space-between
    align-items: stretch
    gap: 12px
    padding: 16px 20px 0

    .vertical
      height: 100%

    &-text
      #{$dark-theme}
        color: $gray-400 !important

  &__message-input
    display: flex
    justify-content: flex-end
    align-items: flex-end
    gap: 12px
    padding: 16px 20px

    @media #{$tablet}
      padding: 16px 8px

  &__container
    position: relative
    width: 100%

  &__scroll-down
    visibility: hidden
    position: absolute
    bottom: 33px // 33px is correct
    right: 31px // 31px is correct
    display: flex
    justify-content: center
    align-items: center
    width: 54px
    height: 54px
    background: $white
    border: .5px solid $outline
    border-radius: 50%
    opacity: 0
    transition: visibility $tr-options, background $tr-options, opacity $tr-options
    cursor: pointer

    #{$dark-theme}
      background: $gray-700

    &:hover
      @media (hover)
        background: $gray-150

        .icon
          #{$dark-theme}
            fill: $text

    @media #{$tablet}
      bottom: calc(100% + 19px) // 19px is correct
      right: 19px // 19px is correct

    &_active
      visibility: visible
      opacity: 1
