.sidebar
  visibility: hidden
  display: flex
  flex-direction: column
  grid-column-start: 1
  grid-row-start: 2
  padding-top: 16px
  background: $gray-150
  border-radius: 16px
  box-shadow: $box-shadow
  opacity: 0
  z-index: 2

  @media #{$tablet}
    transition: visibility $tr-options, opacity $tr-options

  #{$dark-theme}
    background: $gray-700

  #{$sidebar}
    visibility: visible
    opacity: 1

  &_messenger
    box-shadow: none
    transition: visibility $tr-options, opacity $tr-options

  &__header
    display: flex
    padding: 0 16px 16px

  &__body
    display: flex
    flex-direction: column
    flex-grow: 1

  &__menu
    flex-grow: 1

    &-item
      display: flex
      align-items: center
      gap: 8px
      padding: 12px 16px
      border-bottom: 1px solid $gray-300
      color: $text
      transition: background $tr-options
      cursor: pointer
      user-select: none

      #{$dark-theme}
        border-color: $gray-600
        color: $white

      &:hover
        @media (hover)
          background: $gray-250

          #{$dark-theme}
            background: $gray-770

      &_active
        background: $gray-250

        #{$dark-theme}
          background: $gray-770

  &__version
    padding: 16px 0
