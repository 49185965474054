.notification
  position: fixed
  top: 8px
  left: calc(50% - 179px)
  width: 358px
  padding: 16px 12px
  background: $text
  border-radius: 8px
  visibility: hidden
  opacity: 0
  z-index: 3
  transition: visibility $tr-options, opacity $tr-options

  @media #{$mobile}
    width: calc(100% - 16px)
    left: 8px

  &_active
    visibility: visible
    opacity: 1
    animation: show $tr-options
