@mixin field
  padding: 0 12px
  outline: transparent solid 1px
  border-radius: 6px
  color: $gray-850
  font-size: 16px
  line-height: 20px
  transition: outline $tr-options

  &::placeholder
    color: $light

@mixin hide-overflow
  max-width: 100%
  overflow: hidden
  text-overflow: ellipsis

@mixin nowrap
  @include hide-overflow
  white-space: nowrap

@mixin overlay
  position: fixed
  top: 0
  left: 0
  width: 100svw
  height: 100svh
  visibility: hidden
  opacity: 0
  z-index: 3
  transition: visibility $tr-options, opacity $tr-options
  pointer-events: none
