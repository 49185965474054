.popup
  position: fixed
  top: 0
  left: 0
  display: grid
  grid-template-columns: minmax(0, 1fr)
  justify-items: center
  align-items: center
  width: 100%
  height: 100%
  padding: 16px
  background: rgba(49, 67, 74, .8)
  visibility: hidden
  opacity: 0
  z-index: 2
  transition: visibility $tr-options, opacity $tr-options

  #{$dark-theme}
    background: rgb(9, 20, 24, .8)

  &_active
    visibility: visible
    opacity: 1
    animation: show $tr-options

  &__btn
    position: absolute
    top: 8px
    right: 8px

  &__container
    position: relative
    width: 100%
    max-width: 300px
    max-height: 100%
    background: $white
    border-radius: 20px

    #{$dark-theme}
      background: $gray-700

    div
      max-width: 100%

    & > .title
      &:first-of-type
        padding-right: 24px

    &_confirm
      .popup__wrapper
        padding-top: 40px

    &_max-height
      height: 100%

      form
        display: flex
        flex-direction: column
        justify-content: space-between
        height: 100%

    &_408
      max-width: 408px

  &__wrapper
    padding: 24px

  &__footer
    position: sticky
    bottom: 0
    background: red
    padding: 16px 24px 24px
    background: $white
    border-top: 2px solid #dfdfdf
    z-index: 1

    #{$dark-theme}
      background: $gray-700
      border-color: $gray-600
