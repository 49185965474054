.scroll
  overflow: auto

  @supports (overflow: overlay)
    overflow: overlay

  @supports not selector(::-webkit-scrollbar)
    scrollbar-color: $scrollbar-color

  &_macos
    scrollbar-color: $scrollbar-color

  &_margin
    &:not(.scroll_macos)
      &::-webkit
        &-scrollbar
          &-track
            margin: 0 16px

  &:not(&_macos)
    &::-webkit
      &-scrollbar
        width: 6px
        height: 6px

        &-thumb
          background-color: $gray-350
          border-radius: 8px
