.placeholder
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  .app > &
    height: 100svh

  &_delay
    opacity: 0
    animation: show 0s .15s forwards

  &_max-height
    height: 100%

  &__img
    width: 100%
    max-width: 256px
    aspect-ratio: 10 / 9
    background: url('#{$path}/img/empty-folder.png') no-repeat center / contain
