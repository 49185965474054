.text
  display: block
  min-width: 0
  color: $text-main
  transition: color $tr-options

  span
    display: inline-block

  #{$dark-theme}
    color: $white

  &_color

    &-black
      color: $gray-850

    &-blue
      #{$any-theme}
        color: $blue-tg

    &-green
      #{$any-theme}
        color: $green

    &-light
      #{$any-theme}
        color: $light

    &-optional
      color: $text-optional

      #{$dark-theme}
        color: $gray-350

    &-secondary
      color: $text-secondary

      #{$dark-theme}
        color: $gray-300

    &-white
      #{$any-theme}
        color: $white

    &-wild
      #{$dark-theme}
        color: $gray-400

  &_nowrap
    @include nowrap

  &_pre
    white-space: pre-wrap
    word-break: break-word

  &_size

    &-12
      font-size: 12px
      line-height: 16px

    &-14
      font-size: 14px
      line-height: 16px

    &-16
      font-size: 16px
      line-height: 24px

    &-24
      font-size: 24px
      line-height: 32px

    &-72
      font-size: 72px
      line-height: 1

    &-144
      font-size: 144px
      line-height: 1

span.text
  display: inline-block
