.folders
  display: flex
  flex-shrink: 0
  gap: 4px
  padding: 0 12px
  border-bottom: 1px solid $gray-350

  #{$dark-theme}
    border-color: $gray-600

  &__item
    display: flex
    align-items: center
    gap: 4px
    padding: 0 2px 4px
    border-bottom: 2px solid transparent
    transition: border-color $tr-options
    cursor: pointer
    user-select: none

    &:hover
      @media (hover)
        border-color: $blue-tg

    &_active
      border-color: $blue-tg
      cursor: default

      & > .text
        #{$any-theme}
          color: $blue-tg

  &__count
    display: block
    min-width: 20px
    padding: 0px 4px
    background: $blue-tg
    border: 2px solid $white
    border-radius: 72px

    &_muted
      background: $gray-400
