.code
  padding: 8px
  background: #d3fdc4
  border-radius: 999px
  font-family: monospace
  font-size: 14px
  line-height: 16px
  white-space: pre

  #{$dark-theme}
    color: $text
