.mini-btn
  padding: 0 4px
  border-radius: 999px
  font-size: 12px
  transition: background $tr-options, color $tr-options
  cursor: pointer
  user-select: none

  &_green
    background: $green
    color: $white

    &:hover
      @media (hover)
        background: $pressed

  &_white
    background: $white
    color: $green

    &:hover
      @media (hover)
        color: $pressed
