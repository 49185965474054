.vertical
  flex-shrink: 0
  width: 2px

  border-radius: 999px

  &_blue
    background: $blue-tg

  &_gray
    background: $gray-350
