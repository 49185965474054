.btn
  position: relative
  display: flex
  justify-content: center
  align-items: center
  min-width: 32px
  min-height: 48px
  padding: 0 24px
  border-radius: 6px
  font-size: 16px
  font-weight: 600
  line-height: 20px
  cursor: pointer
  user-select: none

  & > span
    @include nowrap

  & > .tooltip
    top: 32px
    left: 0

    @media #{$tablet}
      left: initial
      right: 0

  &:hover
    & > .tooltip
      @media (hover)
        visibility: visible
        opacity: 1

  &:disabled
    opacity: .5
    pointer-events: none

  &_blue-dash
    gap: 4px
    min-height: 40px
    background: transparent
    border: 1px dashed $blue-tg
    color: $blue-tg
    font-weight: 400
    transition: opacity $tr-options

    &:hover
      @media (hover)
        opacity: .8

  &_fade
    border: 1px solid $outline
    color: $gray-850
    transition: border-color $tr-options, color $tr-options, opacity $tr-options

    #{$dark-theme}
      border-color: $gray-200
      color: $gray-200

    &:hover
      @media (hover)
        background: $gray-150
        border-color: $gray-150

        #{$dark-theme}
          color: $text

  &_green
    gap: 4px
    background: $green
    color: $white
    transition: background $tr-options, opacity $tr-options

    .icon
      #{$any-theme}
        fill: $white

    &:not(.btn_submitting)
      &:hover
        @media (hover)
          background: $pressed

  &_green-border
    background: transparent
    border: 1px solid $green
    color: $green
    transition: border-color $tr-options, color $tr-options, opacity $tr-options

    &:hover
      @media (hover)
        border-color: $pressed
        color: $pressed

  &_icon
    &:not(.btn_transparent, .btn_translucent)
      padding: 0 8px

  &_submitting
    color: transparent
    cursor: default
    pointer-events: none

    &:disabled
      opacity: 1

  &_translucent
    min-height: 32px
    padding: 0
    box-shadow: 0 0 0 2px transparent
    transition: background $tr-options, box-shadow $tr-options, opacity $tr-options

    &:hover
      @media (hover)
        background: $gray-250
        box-shadow: 0 0 0 2px $gray-250

        #{$dark-theme}
          background: $gray-800
          box-shadow: 0 0 0 2px $gray-800

  &_transparent
    gap: 4px
    min-height: 32px
    padding: 0
    background: none
    font-weight: 400
    transition: color $tr-options

    #{$dark-theme}
      color: #c8c8c8

    &:hover
      @media (hover)
        color: $primary-light

        .icon
          fill: $primary-light

          .header &
            fill: $blue-tg

          &_color-green
            #{$any-theme}
              fill: $pressed

          &_color-blue
            fill: $blue-tg
            opacity: .8

          &_color-light
            #{$any-theme}
              fill: $green

          &_stroke
            stroke: $primary-light

            &.icon_color-blue
              stroke: $blue-tg

    &.btn_active
      &:hover
        @media (hover)
          .icon
            opacity: .8

      .icon
        .header &
          fill: $blue-tg

  &__progress
    position: absolute
