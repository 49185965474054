.one-time-password
  width: fit-content
  padding: 8px 40px
  background: #d3fdc4
  border-radius: 12px
  font-size: 24px
  font-weight: 600
  line-height: 1

  #{$dark-theme}
    color: $text

  & > span
    &:not(:last-of-type)
      margin-right: 4px
