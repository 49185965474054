.preview
  position: relative

  &__progress-bar
    position: absolute
    top: calc(50% - 24px)
    left: calc(50% - 24px)
    width: 48px
    height: 48px
    padding: 1px
    background: rgb(0, 0, 0, .1)
    border-radius: 50%
    animation: show $tr-options
    z-index: 1
