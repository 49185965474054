.nav
  display: flex
  gap: 8px
  padding: 16px 20px 0
  border-bottom: 2px solid $gray-200

  #{$dark-theme}
    border-color: $gray-600

  .accordion &
    padding: 0

  .aside &
    padding: 16px 0 0

  &__item
    display: block
    padding: 0 4px 4px
    border-bottom: 2px solid transparent
    color: $text-main
    font-weight: 600
    line-height: 24px
    transition: border-color $tr-options, color $tr-options
    cursor: pointer
    user-select: none

    #{$dark-theme}
      color: $white

    &:hover
      @media (hover)
        border-color: $blue-tg

    &_active
      border-color: $blue-tg
      cursor: default

      #{$any-theme}
        color: $blue-tg
