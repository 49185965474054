.wallpapers
  display: grid
  grid-template-columns: repeat(3, 1fr)
  gap: 4px

  &__item
    position: relative
    background-repeat: no-repeat
    background-position: center
    background-size: cover
    border-radius: 12px
    aspect-ratio: 9 / 16
    transition: opacity $tr-options
    cursor: pointer

    &:hover
      @media (hover)
        opacity: .8

  &__check
    position: absolute
    top: 8px
    right: 8px
    display: flex
    justify-content: center
    align-items: center
    width: 26px
    height: 26px
    background: $green
    border-radius: 50%
    opacity: 0
    transition: opacity $tr-options

    &_active
      opacity: 1
