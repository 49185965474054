.inner-message
  display: flex
  align-items: stretch
  gap: 8px
  max-width: 100%
  padding: 12px 12px 16px
  background: #d0deee
  border-radius: 16px 16px 16px 0

  #{$dark-theme}
    background: #5c6f87

  .message-location
    width: 326px
    height: 244.5px

  .media
    max-width: 326px

  &_clickable
    transition: opacity $tr-options
    cursor: pointer
    user-select: none

    &:hover
      @media (hover)
        opacity: .8

  &_disabled
    opacity: .6
    pointer-events: none

  &_quote

    .message__text
      max-width: 100%
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap

  &__wrapper
    max-width: 100%
    overflow: hidden

  &__sender
    color: $gray-500
    font-weight: 600
    line-height: 24px

    #{$dark-theme}
      color: $blue-400
