.textarea
  width: 100%
  max-width: 360px
  max-height: 218px
  padding: 12px 16px
  border: 1px solid $gray-250
  border-radius: 6px
  outline: transparent solid 1px
  color: $gray-850
  font-size: 16px
  font-weight: 500
  line-height: 24px
  transition: outline $tr-options
  resize: none

  #{$dark-theme}
    border-color: #9fa9c5
    color: $white

  &::placeholder
    color: $light

  &_alt
    padding: 8px 12px
    background: $gray-150
    border: none
    font-weight: initial

    #{$dark-theme}
      background: $gray-650

  &_error
    outline: $error solid 1px
