.system-message
  margin: 0 auto
  padding: 6px 20px

  @media #{$tablet}
    padding-left: 8px
    padding-right: 8px

  &__bubble
    width: fit-content
    max-width: 640px
    background: $gray-500
    border-radius: 999px
    padding: 4px 8px

    .text
      white-space: pre-wrap

      #{$dark-theme}
        color: $gray-400

    #{$dark-theme}
      background: $gray-770

    &:disabled
      opacity: .6
      pointer-events: none

    &_alt
      padding: 8px
      border-radius: 16px 16px 16px 0

    &_clickable
      transition: background $tr-options
      cursor: pointer
      user-select: none

      &:hover
        @media (hover)
          background: $gray-600

          #{$dark-theme}
            background: $gray-850
