.dialogs
  display: flex
  flex-direction: column
  grid-column-start: 1
  grid-row-end: 3
  background: $gray-150
  border-radius: 16px
  box-shadow: $box-shadow
  transition: box-shadow $tr-options

  #{$dark-theme}
    background: $gray-700

  @media #{$tablet}
    grid-column-start: 1
    grid-column-end: 2
    grid-row-start: 2
    grid-row-end: 3

  &__header
    background: $gray-150
    border-radius: 16px

    #{$dark-theme}
      background: $gray-700

    &-wrapper
      display: flex
      justify-content: space-between
      align-items: center
      padding: 8px 12px

  &__search
    padding: 0 12px 12px

    & > .input
      max-width: 100%

      & .input__field
        border-radius: 8px

  &__list
    flex-grow: 1

  &__new-chat
    position: relative
    flex-shrink: 0
    height: 32px
    background: $gray-150
    border-top: 1px solid $gray-300
    border-radius: 0 0 16px 16px

    #{$dark-theme}
      background: $gray-700
      border-color: $gray-600

  &__creator
    position: absolute
    bottom: 6.5px
    left: calc(50% - 27px)

  &__button
    display: flex
    justify-content: center
    align-items: center
    width: 54px
    height: 54px
    background: $green
    border-radius: 50%
    transition: background $tr-options
    cursor: pointer

    .icon
      #{$any-theme}
        fill: $white

    &:hover
      @media (hover)
        background: $pressed
