.header
  grid-column-start: 1
  grid-column-end: 3
  padding: 0 24px
  background: $gray-100
  border-radius: 16px
  box-shadow: $box-shadow

  #{$dark-theme}
    background: $gray-700

  @media #{$laptop}
    grid-column-end: 3
    padding: 0 16px

  @media #{$tablet}
    grid-column-end: 2

  .wrapper
    height: 100%

  &__wrapper
    display: flex
    align-items: center
    gap: 24px
    width: 100%

    @media #{$mobile}
      gap: 8px

    .select
      &:nth-of-type(3)
        @media #{$mobile}
          margin-left: auto

        .select__dropdown
          @media #{$mobile}
            left: initial
            right: -16px

  &__manager
    @media #{$tablet}
      display: none
