.reaction-trigger
  display: flex
  align-items: center
  gap: 12px
  max-width: 288px
  margin-left: -8px
  padding: 0 12px
  background: $gray-100
  box-shadow: $box-shadow
  border-radius: 999px

  #{$dark-theme}
    background: rgb(83, 84, 97, .98)

  &__item
    width: 24px
    transition: opacity $tr-options
    white-space: nowrap
    cursor: pointer

    &:hover
      @media (hover)
        opacity: .8

  &__btn
    display: flex
    justify-content: center
    align-items: center
    width: 24px
    height: 24px
    background: $gray-250
    border-radius: 50%
    transition: background $tr-options
    cursor: pointer

    #{$dark-theme}
      background: $gray-500

    &:hover
      @media (hover)
        background: $gray-300

        #{$dark-theme}
          background: $gray-600
