.accordion
  border-bottom: 1px solid $gray-300

  #{$dark-theme}
    border-color: $gray-600

  &__summary
    display: flex
    justify-content: space-between
    align-items: center
    gap: 8px
    padding: 8px 20px
    transition: background $tr-options

    @mixin active
      background: #f8f8f8

      #{$dark-theme}
        background: $gray-650

    &:hover
      @media (hover)
        @include active

    &_active
      @include active

  &__btn-group
    visibility: hidden
    display: flex
    opacity: 0
    transition: visibility $tr-options, opacity $tr-options

    @media (hover: none)
      visibility: visible
      opacity: 1

    .accordion__summary:hover &, .accordion__summary_active &
      @media (hover)
        visibility: visible
        opacity: 1

  &__details
    padding: 20px
