.grid
  display: grid
  grid-template-columns: repeat(5, 1fr)
  grid-gap: 16px 16px

  #{$sidebar}
    grid-template-columns: repeat(4, 1fr)

  @media #{$laptop-l}
    grid-template-columns: repeat(4, 1fr)

    #{$sidebar}
      grid-template-columns: repeat(3, 1fr)

  @media #{$laptop}
    grid-template-columns: repeat(3, 1fr)

    #{$sidebar}
      grid-template-columns: repeat(2, 1fr)

  @media #{$tablet}
    grid-template-columns: repeat(2, 1fr)

  @media #{$mobile}
    grid-template-columns: 1fr
