.card
  position: relative
  display: flex
  flex-direction: column
  min-width: 0
  border: 1px solid $gray-300
  border-radius: 8px
  transition: border-color $tr-options, box-shadow $tr-options
  overflow: hidden

  #{$dark-theme}
    background: #565b62
    border-color: $gray-600

  &:has(.card__menu)
    &:hover
      @media (hover)
        #{$light-theme}
          box-shadow: $box-shadow

        #{$dark-theme}
          border-color: transparent

  &_active
    @media (hover)
      #{$light-theme}
        box-shadow: $box-shadow

      #{$dark-theme}
        border-color: transparent

  &__head
    position: relative
    display: flex
    gap: 8px
    padding: 12px 16px
    border-bottom: 1px solid $gray-300

    #{$dark-theme}
      border-color: $gray-600

  &__badge
    position: absolute
    top: 0
    right: 0
    padding: 0 12px
    background: $green
    border-bottom-left-radius: 8px

  &__body
    padding: 12px 16px 24px

  &__menu
    position: absolute
    bottom: -48px
    display: flex
    justify-content: space-between
    width: 100%
    margin-top: auto
    padding: 8px 12px
    background: #f8f8f8
    transition: bottom $tr-options

    @media (hover: none)
      position: static

    #{$dark-theme}
      background: $gray-770

    .card:hover &, .card_active &
      @media (hover)
        bottom: 0
