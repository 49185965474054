.toggle
  display: flex
  align-items: center
  gap: 8px
  height: 24px
  cursor: pointer
  user-select: none

  &_alt
    justify-content: space-between
    gap: 0
    height: 40px
    padding: 0 12px
    background: $gray-150
    border-radius: 6px

    #{$dark-theme}
      background: $gray-650

  &__btn
    width: 30px
    height: 16px
    background: $gray-300
    border-radius: 16px
    transition: background $tr-options, opacity $tr-options

    #{$dark-theme}
      background: $gray-600

    &::after
      content: ''
      position: relative
      top: 1px
      left: 1px
      display: block
      width: 14px
      height: 14px
      background: $white
      border-radius: 50%
      transition: left $tr-options

    .toggle_alt &
      order: 1

    .toggle:hover &
      @media (hover)
        opacity: .8

    &_active
      #{$any-theme}
        background: $primary-light

      &::after
        left: 15px
