.color-picker
  display: block
  width: 48px
  height: 48px
  border: 1px solid $gray-300
  border-radius: 50%
  transition: opacity $tr-options
  cursor: pointer

  &:hover
    @media (hover)
      opacity: .8

  & > input
    visibility: hidden
    position: relative
    top: -8px
