.vars
  display: flex
  gap: 4px
  flex-wrap: wrap

  &__btn
    padding: 2px 6px
    background: #f3fafc
    border-radius: 999px
    transition: background $tr-options, opacity $tr-options
    cursor: pointer

    #{$dark-theme}
      background: $gray-600

    .text_color-blue
      #{$dark-theme}
        color: $white

    &:hover
      @media (hover)
        opacity: .8

        #{$dark-theme}
          background: $gray-650
          opacity: 1
