.anchor
  display: flex
  align-items: center
  gap: 4px
  width: fit-content
  color: $blue-tg
  font-size: 12px
  line-height: 16px
  transition: opacity $tr-options
  user-select: none
  cursor: pointer

  & > span
    @include nowrap

  &:hover
    @media (hover)
      opacity: .8

  .icon
    width: 12px
    height: 12px

    #{$any-theme}
      fill: $blue-tg

  &:disabled
    opacity: .6
    pointer-events: none

  &_14
    font-size: 14px
    line-height: 16px

  &_16
    font-size: 16px
    line-height: 20px

    .icon
      width: 24px
      height: 24px
