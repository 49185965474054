.chat
  background-color: $gray-100
  background-repeat: no-repeat
  background-position: center
  background-size: 100% calc(100% - 30px)
  box-shadow: $box-shadow
  border-radius: 16px
  z-index: 1

  #{$dark-theme}
    background-color: $gray-700

  @media #{$tablet}
    grid-column-start: 1
    grid-column-end: 2
    grid-row-start: 2
    grid-row-end: 3

  &_placeholder
    padding: 20px

    @media #{$tablet}
      display: none

  &__wrapper
    position: relative
    display: flex
    flex-direction: column
    height: 100%

  &__header
    position: relative
    display: flex
    justify-content: space-between
    align-items: center
    gap: 16px
    padding-right: 20px
    background: $gray-100
    border-bottom: 2px solid $outline
    border-radius: 16px 16px 0 0

    #{$dark-theme}
      background: $gray-700
      border-color: $gray-600

    @media #{$tablet}
      justify-content: space-between
      padding-right: 0

    .context-menu
      top: 58px
      left: initial
      right: 12px

      &__wrapper
        position: initial

    .profile-image
      @media #{$tablet}
        order: 1

    &-user
      display: flex
      align-items: center
      gap: 16px
      flex-grow: 1
      padding: 12px 0 12px 20px
      cursor: pointer
      user-select: none

      @media #{$tablet}
        justify-content: space-between
        padding: 4px 8px
        text-align: center

    &-link
      display: none

      @media #{$tablet}
        display: flex

    &-menu
      @media #{$tablet}
        display: none

  &__title
    @media #{$tablet}
      text-align: center

  &__convo
    flex-grow: 1
    padding: 46px 0

    &-wrapper
      display: flex
      flex-direction: column
      justify-content: flex-end
      flex-grow: 1
      min-height: 100%

  &__input
    flex-grow: 1
    max-height: 160px
    margin-bottom: 6px
    background: none
    font-size: 16px
    line-height: 20px
    resize: none

    #{$dark-theme}
      color: $white

    &::placeholder
      color: $text-secondary
