.media
  position: relative
  display: flex
  justify-content: center
  border-radius: 6px
  overflow: hidden

  div
    display: flex

  img, video
    max-width: 100%
    z-index: 1

  img, video
    object-fit: contain

  &_circle
    border-radius: 50%

  &__substrate
    position: absolute
    width: 100%
    height: 100%
    background-repeat: no-repeat
    background-position: center
    background-size: cover
    filter: blur(8px)
