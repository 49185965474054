.ack
  display: flex

  &:hover
    & > .tooltip
      visibility: visible
      opacity: 1

  & > .tooltip
    display: none

    .message &
      display: initial
      top: 32px

      @media #{$laptop}
        right: 0

  &:not(.ack_whatcrm)
    .ack__delivered
      #{$dark-theme}
        fill: $blue-tg !important

  &_whatcrm
    .ack__delivered, .ack__sent
      fill: $gray-400

  &__read
    #{$dark-theme}
      fill: $blue-tg !important
