.checkbox
  display: flex
  align-items: center
  flex-wrap: nowrap
  gap: 8px
  width: 100%
  max-width: 360px
  cursor: pointer
  user-select: none

  input[type='checkbox']
    &:checked
      & ~ .checkbox__btn
        background: $green

        &::after
          opacity: 1

  &__btn
    flex-shrink: 0
    position: relative
    width: 26px
    height: 26px
    border-radius: 6px
    background: #cecece
    transition: background $tr-options, opacity $tr-options

    &::after
      content: ''
      position: absolute
      top: 3px
      left: 3px
      width: 20px
      height: 20px
      background: url('#{$path}/img/icons/check.svg') no-repeat center / contain
      opacity: 0
      transition: opacity $tr-options

    .checkbox:hover &
      @media (hover)
        opacity: .8

  &__wrapper
    display: flex
    flex-direction: column
