.profile
  position: absolute
  top: 0
  right: 0
  display: flex
  flex-direction: column
  gap: 16px
  width: 380px
  max-width: 100%
  height: 100%
  padding: 16px
  background: $white
  border-radius: 16px
  box-shadow: $box-shadow
  opacity: 0
  z-index: 2
  transition: opacity $tr-options

  #{$dark-theme}
    background: $gray-700

  @media #{$mobile}
    width: 100%

  &_active
    opacity: 1

  &__closer
    align-self: flex-end
    cursor: pointer

    .icon
      stroke: $gray-500

    &:hover
      @media (hover)
        .icon
          stroke: $primary-light

  &__actions
    display: none
    justify-content: center

    @media #{$tablet}
      display: flex

  &__list
    background: $gray-150
    border-radius: 16px

    #{$dark-theme}
      background: $gray-770

  &__item
    display: flex
    justify-content: space-between
    align-items: flex-end
    gap: 8px
    padding: 12px 16px
    cursor: pointer
    user-select: none

    &:hover
      @media (hover)
        .profile__item-icon
          fill: $primary-light

    &:not(:last-child)
      border-bottom: 1px solid $gray-300

    &_block
      color: #c9162b

      &:hover
        @media (hover)
          text-decoration: underline

      .text
        #{$any-theme}
          color: #c9162b

    &-icon
      margin-bottom: -3px
      fill: $text
      cursor: pointer

      #{$dark-theme}
        fill: $white
