.seq
  display: flex
  justify-content: space-between
  align-items: center
  gap: 8px
  padding: 8px 20px
  min-height: 49px
  border-bottom: 1px solid $gray-300
  transition: background $tr-options

  @mixin active
    background: #f8f8f8

    #{$dark-theme}
      background: $gray-650

  #{$dark-theme}
    border-color: $gray-600

  &:hover
    &:has(.seq__btn-group)
      @media (hover)
        @include active

  &_active
    @include active

  &__btn-group
    opacity: 0
    transition: opacity $tr-options

    @media (hover: none)
      opacity: 1

    .seq:hover &
      @media (hover)
        opacity: 1

    .seq_active &
      opacity: 1
