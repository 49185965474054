$black: #000
$blue-400: #96b2ff
$blue-tg: #34aadf
$error: #ff334b
$gray-100: #fcfcfc
$gray-150: #f5f5f5
$gray-200: #efefef
$gray-250: #e8e8e8
$gray-300: #dfdfdf
$gray-350: #c8c8c8
$gray-400: #9faac6
$gray-500: #8895a8
$gray-600: #6a6e76
$gray-650: #535461
$gray-700: #3e434a
$gray-770: #243243
$gray-800: #2a2a2a
$gray-850: #1f1f1f
$green: #00a86b
$light: #949494
$outline: #efefef
$pressed: #1e6e1e
$primary-light: #06c755
$scrollbar-color: $gray-350 transparent
$text-main: #4c4c4c
$text-optional: #939b9f
$text-secondary: #777777
$text: #0e1114
$white: #ffffff

$laptop-l: '(max-width: 1440px)'
$laptop: '(max-width: 1024px)'
$tablet: '(max-width: 768px)'
$mobile: '(max-width: 425px)'
$mobile-s: '(max-width: 374px)'

$box-shadow: 0 4px 35px rgba(0, 0, 0, .10)
$path: '../../public/static/'
$tr-options: .15s ease-in-out

$any-theme: '.dark-theme &, &:not(.dark-theme &)'
$dark-theme: '.dark-theme &'
$light-theme: '&:not(.dark-theme &)'
$sidebar: '.workspace_sidebar &'

@keyframes show
  0%
    visibility: hidden
    opacity: 0

  100%
    visibility: visible
    opacity: 1

// margins
// 4
// 8
// 12
// 16
// 20
// 32
// 44
