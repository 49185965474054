[data-rmiz]
  position: relative

[data-rmiz-ghost]
  position: absolute
  pointer-events: none

[data-rmiz-btn-zoom], [data-rmiz-btn-unzoom]
  display: none

[data-rmiz-btn-zoom]:not(:focus):not(:active)
  position: absolute
  clip: rect(0 0 0 0)
  clip-path: inset(50%)
  height: 1px
  overflow: hidden
  pointer-events: none
  white-space: nowrap
  width: 1px

[data-rmiz-btn-zoom]
  position: absolute
  inset: 10px 10px auto auto
  cursor: zoom-in

[data-rmiz-btn-unzoom]
  position: absolute
  inset: 20px 20px auto auto
  cursor: zoom-out
  z-index: 1

[data-rmiz-content="found"] img, [data-rmiz-content="found"] svg, [data-rmiz-content="found"] [role="img"],[data-rmiz-content="found"] [data-zoom] 
  cursor: zoom-in

[data-rmiz-modal]::backdrop
  display: none

[data-rmiz-modal][open]
  position: fixed
  width: 100vw
  width: 100dvw
  height: 100svh
  height: 100dvh
  max-width: none
  max-height: none
  margin: 0
  padding: 0
  border: 0
  background: transparent
  overflow: hidden

[data-rmiz-modal-overlay]
  position: absolute
  inset: 0
  transition: background-color $tr-options

[data-rmiz-modal-overlay="hidden"] 
  background-color: rgba(255, 255, 255, 0)

[data-rmiz-modal-overlay="visible"] 
  background-color: rgba(49, 67, 74, .8)

[data-rmiz-modal-content]
  position: relative
  width: 100%
  height: 100%

[data-rmiz-modal-img]
  position: absolute
  cursor: zoom-out
  image-rendering: high-quality
  transform-origin: top left
  transition: .25s ease-in-out
