.chips
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-gap: 16px 16px
  min-width: 100%

  &__item
    display: flex
    justify-content: center
    align-items: center
    aspect-ratio: 1 / 1
    padding: 4px
    border: 2px solid transparent
    border-radius: 16px
    box-shadow: $box-shadow
    transition: border-color $tr-options
    cursor: pointer

    #{$dark-theme}
      background: #565b62

    & > img
      width: 90px
      height: 90px

    & > .icon
      width: 100%
      height: 100%

    &:disabled
      opacity: .5
      pointer-events: none

    &:hover
      @media (hover)
        border-color: $green

    &_active
      border-color: $green
      pointer-events: none
