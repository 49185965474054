.reaction-picker
  position: absolute
  top: 0
  left: 0

  &__mart
    position: absolute
    visibility: hidden
    position: absolute
    opacity: 0
    transition: visibility $tr-options, opacity $tr-options
    z-index: 2
    pointer-events: initial

    .reaction-picker_active &
      visibility: visible
      opacity: 1

    & em-emoji-picker
      width: 228px
      height: 304px
      overflow: hidden
