.chips-alt
  display: flex
  gap: 8px
  flex-wrap: wrap

  &__option
    position: relative
    display: flex
    justify-content: center
    align-items: flex-end
    flex-shrink: 0
    padding: 16px
    border: 1px solid $gray-300
    border-radius: 12px
    transition: border-color $tr-options
    cursor: pointer
    user-select: none

    #{$dark-theme}
      background: $gray-650
      border-color: transparent

    img
      width: 72px
      max-width: 100%
      aspect-ratio: 4 / 5
      pointer-events: none

    &:hover
      @media (hover)
        border-color: $green

    &_active
      pointer-events: none

      #{$any-theme}
        border-color: $green

  &__checkbox
    position: absolute
    bottom: 8px
    right: 8px
    display: flex
    justify-content: center
    align-items: center
    width: 24px
    height: 24px
    background: rgb(0, 0, 0, .1)
    border: 1px solid $white
    border-radius: 50%
    transition: background $tr-options, border-color $tr-options
    box-sizing: border-box

    .chips-alt__option_active &
      background: $green
      border-color: $green

    .icon
      opacity: 0
      transition: opacity $tr-options

      .chips-alt__option_active &
        opacity: 1
