.link
  display: inline-flex
  align-items: center
  gap: 4px
  color: $blue-tg
  font-size: 16px
  line-height: 20px
  transition: opacity $tr-options

  &:hover
    @media (hover)
      opacity: .8

  .text &
    font-size: inherit

  .text_nowrap &
    display: inline

  &_text-align-center
    text-align: center
