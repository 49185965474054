.tariffs
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 16px 16px

  @media #{$tablet}
    grid-template-columns: 1fr

  #{$sidebar}
    @media #{$laptop}
      grid-template-columns: 1fr
