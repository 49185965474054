.radio
  display: flex
  flex-direction: column
  gap: 8px
  width: max-content
  max-width: 100%

  &_disabled
    opacity: .5
    pointer-events: none

  &__item
    display: flex
    align-items: center
    gap: 8px
    transition: opacity $tr-options
    cursor: pointer
    user-select: none

  &__grid
    display: flex
    flex-direction: column
    flex-grow: 1
    gap: 6px
    min-width: 0

  &__button
    position: relative
    flex-shrink: 0
    width: 26px
    height: 26px
    background: #d1dae5
    border: 1px solid $white
    border-radius: 50%
    transition: background $tr-options, opacity $tr-options

    &::after
      content: ''
      position: absolute
      top: 2px
      left: 2px
      width: 20px
      height: 20px
      background: url('#{$path}/img/icons/check.svg') no-repeat center / contain
      opacity: 0
      transition: opacity $tr-options

    .radio__item:hover &
      @media (hover)
        opacity: .8

    .radio__item_active &
      background: $green

      &::after
        opacity: 1

  &__label
    display: block
    line-height: 20px
