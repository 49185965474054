.voice

  &__delete
    display: flex
    align-items: center
    gap: 4px
    color: $error
    font-size: 16px
    line-height: 20px
    transition: opacity $tr-options
    cursor: pointer

    &:hover
      @media (hover)
        opacity: .8

  &__btn
    position: relative
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 44px
    height: 44px
    margin: -6px
    border-radius: 50%
    z-index: 0
    transition: background $tr-options
    cursor: pointer

    .tooltip
      bottom: calc(100% + 4px)
      right: 0

    &:not(&_active)
      &:hover
        .tooltip
          visibility: visible
          opacity: 1

        @media (hover)
          .icon
            fill: $primary-light

    &::before, &::after
      content: ''
      position: absolute
      background: #00a86b
      z-index: -1

    &_active
      background: $green

      &::before
        width: calc(100% + 6px)
        height: calc(100% + 6px)
        animation: wiggle-before 1s ease-in-out infinite forwards
        opacity: .2

        @keyframes wiggle-before
          0%, 100%
            border-radius: random(20) + 40 + px random(20) + 40 + px random(20) + 40 + px random(20) + 40 + px

          25%
            border-radius: random(20) + 40 + px random(20) + 40 + px random(20) + 40 + px random(20) + 40 + px

          75%
            border-radius: random(20) + 40 + px random(20) + 40 + px random(20) + 40 + px random(20) + 40 + px

      &::after
        width: calc(100% + 12px)
        height: calc(100% + 12px)
        animation: wiggle-after 1s ease-in-out infinite forwards
        opacity: .1

        @keyframes wiggle-after
          0%, 100%
            border-radius: random(20) + 40 + px random(20) + 40 + px random(20) + 40 + px random(20) + 40 + px

          25%
            border-radius: random(20) + 40 + px random(20) + 40 + px random(20) + 40 + px random(20) + 40 + px

          75%
            border-radius: random(20) + 40 + px random(20) + 40 + px random(20) + 40 + px random(20) + 40 + px

      .icon
        fill: $white
