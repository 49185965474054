.react-code-input
  display: flex !important
  gap: 8px
  height: 40px

  input
    width: 40px
    background: $gray-150
    border-radius: 6px
    outline: transparent solid 1px
    color: $gray-850
    transition: outline-color $tr-options
    font-size: 16px
    font-weight: 500
    line-height: 20px
    text-align: center
    text-transform: uppercase

    #{$dark-theme}
      background: $gray-650
      color: $white

  &_space-between
    justify-content: space-between
    gap: 0

    input
      @media #{$mobile}
        width: 32px
