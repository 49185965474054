.logo
  flex-shrink: 0
  width: 145.92px
  height: 24px
  background: url('#{$path}/img/logo.svg') no-repeat center / contain

  #{$dark-theme}
    background-image: url('#{$path}/img/logo_alt.svg')

  @media #{$tablet}
    width: 33.66px
    height: 24px

    #{$any-theme}
      background: url('#{$path}/img/logo_min.svg') no-repeat right center / contain

  @media #{$mobile}
    display: none
